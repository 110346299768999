@import '../../../styles/mixins.scss';

/*Список книг - скролл*/
.scrollCards {
  position: relative;
  overflow: auto;
  margin-bottom: 40px;
  &__wrap {
    display: flex;
    width: fit-content;
    padding-bottom: 20px;
  }
}

.scrollCard {
  width: 312px;
  height: auto;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 20px;
  margin-right: 20px;
  @include max-screen-530 {
    width: 280px;
    padding: 12px;
  }
  &:last-child {
    margin-right: 0;
  }

  &_advert {
    display: flex;
    flex-direction: column;
    .scrollCard__imgWrap {
      width: 116px;
      height: 164px;
      @include max-screen-530 {
        width: 120px;
        height: 187px;
      }
    }
    .scrollCard__img {
      width: 110%;
      left: -6px;
    }
  }

  &__info {
    display: flex;
    margin-bottom: 8px;
  }
  &__imgWrap {
    width: 104px;
    height: 145px;
    position: relative;
    margin-right: 10px;
    color: transparent;
    background: #f9f9f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    &:hover {
      color: transparent;
    }
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 52px;
      height: 64px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;
    border: none;
  }
  &__desc {
    flex: 1;
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-decoration: none;
    display: block;
    margin-bottom: 15px;
    word-break: break-word;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
  &__author {
    font-size: 14px;
    font-weight: bold;
    color: #5a76db;
    text-decoration: none;
    display: block;
    margin-bottom: 15px;
    &:hover {
      color: #5a76db;
      text-decoration: none;
    }
  }
  &__rating {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 18px;
    font-size: 14px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 45%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background: url('/icons/star-icon.svg') no-repeat 0 0;
      background-size: cover;
    }
  }
  &__ratingNumber {
    margin-right: 3px;
    color: #5976db;
  }
  // &__ratingVotes {
  //   color: #999;
  // }
  &__text {
    font-size: 14px;
    line-height: 22px;
    color: #000;
  }
  &__btn {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    outline: none;
    border: none;
    cursor: pointer;
    user-select: none;
    border-radius: 8px;
    padding: 12px 32px;
    font-size: 16px;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    background: #e9eeff;
    color: #5976db;
    transition: background 0.3s ease, color 0.3s ease;
    width: 100%;
    margin-top: auto;
    &:hover {
      background: #d0dcff;
      color: #3f61dd;
    }
  }
}
