.cardsTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  &::first-letter {
    text-transform: uppercase;
  }
}

@media screen and (max-width: 530px) {
  .cardsTitleWrap {
    margin-bottom: 10px;
  }
  .cardsTitleWrap .cardsControls_mb {
    margin-bottom: 10px;
  }
}
