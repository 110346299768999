.scrollCards {
  position: relative;
  overflow: auto;
  margin-bottom: 40px;
  &__wrap {
    display: flex;
    width: fit-content;
    padding-bottom: 20px;
  }
}

.scrollCard {
  width: 173px;
  height: auto;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  &__info {
    display: flex;
    flex-direction: column;
  }
  &__imgWrap {
    width: 175px;
    height: 242px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    color: transparent;
    margin-bottom: 10px;
    background: #f9f9f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 78px;
      height: 96px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
    }
    &:hover {
      color: transparent;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;
    border: none;
  }
  &__desc {
    flex: 1;
  }
  &__title {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    text-decoration: none;
    display: block;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}
