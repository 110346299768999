.scrollXWrapper {
  width: 100%;
  height: auto;
  position: relative;
}

.scrollXWrapper_authors:after {
  height: 85%;
}

.scrollXWrapper:after {
  position: absolute;
  top: -5px;
  right: -0.9%;
  z-index: 1;
  width: 35px;
  height: 95%;
  background: linear-gradient(90deg, #fff 50%, hsla(0, 0%, 100%, 0));
  transform: scaleX(-1);
  content: '';
}

.scrollCards {
  position: relative;
  overflow: auto;
  margin-bottom: 40px;
}

.scrollCards__wrap {
  display: flex;
  width: fit-content;
  padding-bottom: 20px;
}

/*Стилизация скроллбара*/
/* Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #c5c5c5 #f4f4f4;
}

/* Chrome, Edge и Safari */
.scrollbar::-webkit-scrollbar {
  height: 8px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 4px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
}

@media screen and (max-width: 378px) {
  .scrollXWrapper:after {
    right: -3%;
    width: 20px;
  }
}
