.bookCardsList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 20px;
}

.bookCardsList_mb {
  margin: 0 -10px 0;
}

.bookCardsList div[data-card='hidden'] {
  display: none;
}

/*Кнопка 'показать еще'*/
.moreCardsBtn {
  text-align: center;
  margin-bottom: 20px;
}

.moreCardsBtn__txt {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #5976db;
  padding-left: 20px;
  cursor: pointer;
}

.moreCardsBtn__txt:after {
  position: absolute;
  content: '';
  top: 1px;
  left: 0;
  width: 14px;
  height: 15px;
  background: url('/icons/arrow-round-icon.svg') no-repeat 0 0;
  background-size: cover;
  transition: transform 0.6s ease-in-out 0s;
}

.moreCardsBtn:hover .moreCardsBtn__txt:after {
  transform: rotate(360deg);
}

@media screen and (max-width: 378px) {
  .bookCardsList {
    justify-content: center;
  }
  .moreCardsBtn__txt {
    font-size: 14px;
    padding-left: 14px;
  }

  .moreCardsBtn__txt:after {
    top: 1.1px;
    width: 11px;
    height: 12px;
  }
}
