@import '../../../styles/mixins.scss';

.scrollReview {
  width: 304px;
  height: auto;
  margin-right: 20px;
  @include max-screen-1060 {
    width: 280px;
  }

  @include max-screen-375 {
    width: 220px;
    margin-right: 10px;
  }
  &:last-child {
    margin-right: 0;
  }
  &__info {
    display: flex;
    margin-bottom: 8px;
    @include max-screen-530 {
      display: block;
    }
  }
  &__imgWrap {
    width: 55px;
    height: 86px;
    position: relative;
    border-radius: 4px;
    margin-right: 9px;
    overflow: hidden;
    color: transparent;
    background: #f9f9f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 26px;
      height: 32px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
    }

    @include max-screen-530 {
      display: none;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;
    border: none;
  }
  &__desc {
    flex: 1;
  }
  &__date {
    font-size: 12px;
    color: #aeaeae;
    margin-bottom: 4px;
  }
  &__title {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    color: #000;
    text-decoration: none;
    display: block;
    margin-bottom: 2px;
  }
  &__rating {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 18px;
    font-size: 14px;
    margin-bottom: 10px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 45%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background: url('/icons/star-icon.svg') no-repeat 0 0;
      background-size: cover;
    }
  }
  &__ratingNumber {
    margin-right: 3px;
    color: #5976db;
  }
  &__text {
    font-size: 14px;
    line-height: 22px;
    color: #000;
  }
  &__link {
    display: inline-block;
    color: #5976db;
    font-size: 13px;
    transition: color 0.3s ease;
    &:hover {
      color: #5976db;
    }
  }
}
