/*Список авторов - скролл*/
.scrollAuthors {
  width: 100%;
  height: 70px;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}

.scrollAuthors__wrap {
  width: max-content;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin: 0 -10px;
}

.scrollAuthors__item {
  width: 220px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.scrollAuthors__imgWrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 10px;
}

.scrollAuthors__imgWrap:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background: url('/icons/writer-icon.svg') no-repeat 0 0;
  background-size: cover;
  border-radius: 8px;
}

.scrollAuthors__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  display: block;
  color: transparent;
}

.scrollAuthors__link {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #575757;
  text-decoration: none;
  width: calc(100% - 50px);
}

.scrollAuthors__link:hover {
  color: #000;
  text-decoration: none;
}
